<template>
  <div>
    <v-table
      ref="table"
      path="/cars?populate=sheet,sheetDriver,techInspect,insurance"
      table-sm
      :filter="tableFilters"
      :fields="carsNewTableColumns"
      :hide-create-button="hideCreateBtn"
      :tbody-tr-class="tableRowClass"
    >

      <template v-slot:create-button>
        <div class="col-md-4">
          <b-form-checkbox
            v-model="showHidden"
            value="1"
            unchecked-value="0"
          >Показать скрытые</b-form-checkbox>
        </div>
        <div class="col-md-4">
          <b-button
            variant="primary"
            @click="$emit('create')"
          >
            Добавить
          </b-button>
        </div>
      </template>
      <template #cell(serial)="data">
        <v-table-serial-column
          :data="data"
        />
      </template>
      <template #cell(number)="{ item }">
        <a
          @click.prevent="$router.push({name: 'cars-sheets', query: { car_id: item.id }})"
        >{{ item.number }}</a>
      </template>
      <template #cell(mark)="{ item }">
        <a
          @click.prevent="$emit('view', item)"
        >
          {{ item.mark }} {{ item.model }}
        </a>
      </template>
      <template #cell(plan_date)="{ item }">
        <input
          class="border-light text-black-50"
          type="date"
          :value="item.plan_date"
          @input="(event) => onDateInputHandler('plan_date', item.id, event)"
        >
      </template>
      <template #cell(repair_date)="{ item }">
        <input
          class="border-light text-black-50"
          type="date"
          :value="item.repair_date"
          style="transition: background-color .5s, border-color .5s;"
          @input="(event) => onDateInputHandler('repair_date', item.id, event)"
        >
      </template>
      <template #cell(sheet_driver)="{ item }">
        <div class="sheet-driver">
          <div
            :class="{
              'cursor-pointer': true,
              'bg-light': item.sheet === null
            }"
            @click.prevent="onDriverClickHandler(item)"
          >
            <span v-if="item.sheet_driver">
              {{ `${item.sheet_driver.last_name} ${item.sheet_driver.first_name} ${item.sheet_driver.middle_name}`.slice(0, 25) }}
            </span>
            <span v-else>&#160;</span>
          </div>
          <div
            v-if="item.sheet"
            class="sheet-driver-act"
          >
            <button
              v-if="item.sheet.status === 'NEW'"
              class="btn btn-primary btn-sm text-nowrap"
              @click.prevent="onActButtonHandler(item)"
            >
              Сформировать акт выдачи
            </button>
            <button
              v-else-if="item.sheet.status === 'ACTIVE'"
              class="btn btn-warning btn-sm text-nowrap"
              @click.prevent="onActButtonHandler(item)"
            >
              Закрыть акт выдачи
            </button>
          </div>
        </div>
      </template>
      <template #cell(actions)="{ item }">
        <b-dropdown
          dropleft
          no-caret
          variant="white"
          size="sm"
          class="action-buttons"
        >
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" />
          </template>
          <v-table-actions-column
            @edit="$emit('edit', item)"
            @view="$emit('view', item)"
            @delete="$emit('delete', item)"
          >
            <template v-slot:afterButtons>
              <span
                class="text-info cursor-pointer ml-1"
                @click="onRefreshClickHandler"
              >
                <feather-icon icon="RefreshCcwIcon" />
              </span>
            </template>
          </v-table-actions-column>
        </b-dropdown>
      </template>
    </v-table>
    <b-modal
      ref="car-driver-edit"
      :hide-footer="true"
      :title="`Присвоить водителя для ${actModalCarTitle}`"
      @shown="onDriverEditShown"
    >
      <b-overlay
        :show="driverFormData.request"
      >
        <driver-select-field
          :value="driverFormData.sheet_driver"
          :filter="{ has_car_contract: 1 }"
          @input="(driver) => {
            driverFormData.sheet_driver = driver
            driverFormData.sheet_updated = true
          }"
        />
        <div class="btn-group btn-group-sm w-100 mb-1 mt-1">
          <button
            class="btn btn-light"
            @click="$refs['car-driver-edit'].hide()"
          >Отмена</button>
          <button
            v-if="driverFormData.sheet_driver !== null"
            class="btn btn-danger"
            @click="() => {
              clearCarDriver()
            }"
          >Очистить</button>
          <button
            v-if="driverFormData.sheet_updated"
            class="btn btn-success"
            @click="() => {
              onSaveButtonHandler()
            }"
          >Сохранить</button>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal
      ref="car-act-edit"
      :title="actModalTitle"
      hide-footer
    >
      <div class="row">
        <div
          class="col-4 font-weight-bolder"
        >Автомобиль</div>
        <div class="col-8">
          {{ actModalCarTitle }}
        </div>
      </div>
      <div class="row mt-1">
        <div
          class="col-4 font-weight-bolder"
        >Водитель</div>
        <div class="col-8">
          {{ actModalDriverTitle }}
        </div>
      </div>
      <div class="row mt-2 mb-1">
        <div class="col">
          <button
            class="btn btn-primary w-100"
            @click="onActContinueHandler"
          >Продолжить</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VTable, { VTableActionsColumn, VTableSerialColumn } from '@/views/components/vtable'
import {
  BModal,
  BOverlay,
  BDropdown,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import DriverSelectField from '@/views/components/DriverSelectField.vue'
import moment from 'moment'
import { mapState } from 'vuex'
import { carsNewTableColumns } from '../cars_use'

export default {
  components: {
    DriverSelectField,
    VTable,
    VTableSerialColumn,
    VTableActionsColumn,
    BModal,
    BOverlay,
    BDropdown,
    BButton,
    BFormCheckbox,
  },
  props: {
    hideCreateBtn: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      carsNewTableColumns,
      currentCar: null,
      actFormData: {
        car: null,
        request: false,
      },
      driverFormData: {
        car_id: null,
        sheet_id: null,
        sheet_driver: null,
        sheet_updated: false,
        request: false,
      },
      showHidden: '0',
    }
  },
  computed: {
    ...mapState({
      search: state => state.app.globalSearch,
    }),
    tableFilters() {
      return {
        ...this.filter,
        group_id__null: 1,
        search: this.search,
        hide_crm: this.showHidden,
      }
    },
    actModalTitle() {
      if (this.currentCar === null) {
        return ''
      }
      return `${this.currentCar.sheet === null || this.currentCar.sheet.status === 'NEW' ? 'Открытие акта' : 'Закрытие акта'}`
    },
    actModalCarTitle() {
      if (this.currentCar === null) {
        return ''
      }
      return `${this.currentCar.mark} ${this.currentCar.model} ${this.currentCar.number}`
    },
    actModalDriverTitle() {
      if (this.currentCar === null || this.currentCar.sheet_driver === null) {
        return ''
      }
      return `${this.currentCar.sheet_driver.last_name} ${this.currentCar.sheet_driver.first_name} ${this.currentCar.sheet_driver.middle_name}`
    },
  },
  methods: {
    printAct(postFix = 'open', data) {
      this.$axios.post(`print/car-act-${postFix}`, { id: data.act_id }, { responseType: 'blob' })
    },
    clearCarDriver() {
      this.$bvModal.msgBoxConfirm(
        'Очистить?', {
          centered: true,
          okTitle: 'Удалить',
          cancelTitle: 'Отмена',
        },
      ).then(status => {
        if (status) {
          this.$axios.delete(`/cars/sheets/${this.currentCar.sheet.id}`).then(() => {
            this.$refs['car-driver-edit'].hide()
            this.refresh()
            this.clearDriverFormData()
          })
        }
      })
    },
    clearDriverFormData() {
      this.driverFormData.car_id = null
      this.driverFormData.sheet_id = null
      this.driverFormData.sheet_driver = null
    },
    refresh() {
      this.$refs.table.refresh()
    },
    onDriverClickHandler(item) {
      this.currentCar = item
      if (item.sheet === null || item.sheet.status === 'NEW') {
        this.clearDriverFormData()
        this.driverFormData.car_id = item.id
        if (item.sheet !== null && item.sheet.status === 'NEW') {
          this.driverFormData.sheet_id = item.sheet.id
          this.driverFormData.sheet_driver = item.sheet_driver
        }
        this.$refs['car-driver-edit'].show()
      }
    },
    onDriverEditShown(e) {
      setTimeout(() => {
        e.target.querySelector('input').focus()
      }, 100)
    },
    onSaveButtonHandler() {
      if (this.driverFormData.sheet_id === null && this.driverFormData.sheet_driver === null) {
        return
      }
      this.driverFormData.request = true
      // eslint-disable-next-line no-nested-ternary
      const method = this.driverFormData.sheet_id === null ? 'post' : (this.driverFormData.sheet_driver === null ? 'delete' : 'put')
      const url = `/cars/sheets${this.driverFormData.sheet_id ? `/${this.driverFormData.sheet_id}` : ''}`
      const data = {
        driver_id: this.driverFormData.sheet_driver ? this.driverFormData.sheet_driver.id : null,
        car_id: this.driverFormData.car_id,
      }
      this.$axios[method](url, data).then(() => {
        this.$refs['car-driver-edit'].hide()
        this.$refs.table.refresh()
      }).catch(() => {
        this.$refs['car-driver-edit'].hide()
      }).finally(() => {
        this.driverFormData.request = false
      })
    },
    onActButtonHandler(car) {
      this.currentCar = car
      this.$refs['car-act-edit'].show()
    },
    onActContinueHandler() {
      let data = {}
      if (this.currentCar.sheet.status === 'NEW') {
        data = {
          date_start: moment().format('YYYY-MM-DD HH:mm:00'),
          date_end: moment().add('day', 7).format('YYYY-MM-DD HH:mm:00'),
          driver_id: this.currentCar.sheet.driver_id,
          mileage_start: this.currentCar.mileage,
          car_id: this.currentCar.id,
          status: 'ACTIVE',
        }
      } else if (this.currentCar.sheet.status === 'ACTIVE') {
        data = {
          date_end: moment().format('YYYY-MM-DD HH:mm:00'),
          status: 'CLOSED',
        }
      }
      this.$axios.put(`/cars/sheets/${this.currentCar.sheet.id}`, data).then(response => {
        this.$refs.table.refresh()
        this.$refs['car-act-edit'].hide()
        this.printAct(this.currentCar.sheet.status === 'ACTIVE' ? 'close' : 'open', response.data.data)
        this.currentCar = null
      })
    },
    onDateInputHandler(field, carId, event) {
      clearTimeout(this.dateInputTimeout)
      event.target.classList.add('border-secondary', 'bg-secondary')
      this.dateInputTimeout = setTimeout(
        () => {
          const data = {}
          event.target.classList.add('bg-')
          data[field] = event.target.value
          this.$axios.put(`/cars/${carId}`, data).then(() => {
            event.target.classList.remove('border-secondary', 'bg-secondary')
            event.target.classList.add('border-success', 'bg-success')
          }).catch(() => {
            event.target.classList.remove('border-secondary', 'bg-secondary')
            event.target.classList.add('border-danger', 'bg-danger')
          }).finally(() => {
            setTimeout(() => {
              event.target.classList.remove('border-success', 'bg-success', 'border-danger', 'bg-danger')
            }, 1000)
          })
        }, 1000,
      )
    },
    onRefreshClickHandler() {
      console.log()
    },
    tableRowClass(item, type) {
      if (!item || type !== 'row') return
      // eslint-disable-next-line consistent-return
      if (item.repair_date) return 'table-danger'
      // eslint-disable-next-line consistent-return
      if (item.sheet_driver === null) return 'table-success'
    },
  },
}
</script>
<style>
.sheet-driver{
  position: relative;
}
.sheet-driver .sheet-driver-act{
  display: none;
  position: absolute;
  left: 99%;
  top: -4px;
  z-index: 1;
}
.sheet-driver:hover .sheet-driver-act{
  display: block;
}
.action-buttons button{
  padding-left: 0!important;
  padding-right: 0!important;
}
.action-buttons .dropdown-menu>div{
  justify-content: center !important;
}
</style>
